//DefaultLayouts js
// pages pour les utilisateurs connecté

import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useStateContext } from "../Context/ContextProvider";
import axios from "axios";
import logo from "./../images/logo.png";

export default function DefaultLayout() {
  const navigate = useNavigate();
  const { token, setToken, setUser } = useStateContext();
  const [role, setRole] = useState();
  const [logout, setLogout] = useState(false);

  //Menu activate
  const location = useLocation();

  // Fonction pour vérifier quelle page est active
  const checkActivePage = (page) => {
    if (page !== "/users") {
      return location.pathname === page ? "active" : "";
    } else {
      return location.pathname === page ? "activeUser" : "notuser";
    }
  };

  // Rendu conditionnel du lien de paramétrage en fonction du rôle de l'utilisateur
  const renderParametrageLink = () => {
    if (role === "ADM") {
      return (
        <Link to="/parametrage" className={checkActivePage("/parametrage")}>
          Paramétrage
        </Link>
      );
    }
  };

  const onLogout = async (ev) => {
    ev.preventDefault();

    // Envoyer une requête POST à l'endpoint de déconnexion
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Inclure le token d'authentification dans l'en-tête de la requête
          },
        }
      )
      .then((response) => {
        // Vérifier si la déconnexion a réussi
        if (response.status === 200) {
          // Supprimer le token du stockage local
          localStorage.removeItem("ACCESS_TOKEN");
          setUser(null);
          setToken(null);
        } else {
          console.error("La déconnexion a échoué:", response.statusText);
        }
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du chargement des données:",
          error
        );
      });
  };

  //Redirection utilisateurs vers la pages login si non authentifié ou déconnecté
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  // Envoyer une requête GET pour récupérer l'utilisateurs connecté
  useEffect(() => {
    const show = async () => {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`, //authorization token pour récupérer l'utilisateurs
          },
        })
        .then((response) => {
          setRole(response.data.user.profil); //récupéré profil utilisateurs
        })
        .catch((error) => {
          console.error(
            "Une erreur s'est produite lors du chargement des données:",
            error
          );
        });
    };

    show();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  // Réinitialiser le menu à chaque changement de localisation
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // Rendu du composant
  return (
    <>
      <div className={logout === false ? 'display-none' : 'alert-deconnexion'}>
        <div className={logout === false ? 'display-none' : 'box-alert'}>
          <h1 className="text-center mt-20">!</h1>
          <h2 className="text-center">Voulez-vous vraiment vous déconnecter ?</h2>
          <div className="mt-20 block-btn-responsive-380">
            <button type="button" className="btn btn-responsive-380" onClick={onLogout}>
              Oui
            </button>
            <button type="button" className="btn ml-5 btn-responsive-380" onClick={() => {setLogout(false)}}>
              Annuler
            </button>
          </div>
        </div>
      </div>
      <div className="nav-shadow">
        <div className="nav-bar">
          <div className="containt-logo">
            <img src={logo} alt="Logo de l'eglise" className="login-logo" />
          </div>
          <aside className="block-nav">
            <div className="nav">
              <div className="menu-responsive">
                <Link to="/accueil" className={checkActivePage("/accueil")}>
                  Accueil
                </Link>
                <Link to="/membre" className={checkActivePage("/membre")}>
                  Membre
                </Link>
                <Link
                  to="/cotisation"
                  className={checkActivePage("/cotisation")}
                >
                  Cotisation
                </Link>
                <Link to="/reporting" className={checkActivePage("/reporting")}>
                  Reporting
                </Link>
                {renderParametrageLink()}
              </div>
              <div className="burger-menu">
                <div
                  className={`menu-icon ${isOpen ? "open" : ""}`}
                  onClick={toggleMenu}
                >
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </div>
                <div className={`menu-content ${isOpen ? "open" : ""}`}>
                  <ul>
                    <li>
                      <Link to="/accueil">Accueil</Link>
                    </li>
                    <li>
                      <Link to="/membre">Membre</Link>
                    </li>
                    <li>
                      <Link to="/cotisation">Cotisation</Link>
                    </li>
                    <li>
                      <Link to="/reporting">Reporting</Link>
                    </li>
                    <li className={role === "ADM" ? "" : "display-none"}>
                      <Link to="/parametrage">Paramétrage</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
          <div className="deconnexion ml-5">
            <button
              href=""
              onClick={() => {setLogout(true)}}
              className="btn-deconnexion"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-5"
              >
                <path
                  d="M18 3.99994H21C21.5304 3.99994 22.0391 4.21065 22.4142 4.58573C22.7893 4.9608 23 5.46951 23 5.99994V6.99994M18 19.9999H21C21.5304 19.9999 22.0391 19.7892 22.4142 19.4142C22.7893 19.0391 23 18.5304 23 17.9999V16.9999M18.001 11.9999H23.001M23.001 11.9999L21.001 9.99994M23.001 11.9999L21.001 13.9999M6.425 19.4279L12.425 21.2279C12.7237 21.3176 13.0392 21.3362 13.3463 21.2822C13.6535 21.2282 13.9437 21.1031 14.1939 20.9169C14.4441 20.7308 14.6473 20.4887 14.7872 20.21C14.9272 19.9313 15.0001 19.6238 15 19.3119V4.68794C15.0001 4.37609 14.9272 4.06855 14.7872 3.78987C14.6473 3.5112 14.4441 3.26911 14.1939 3.08295C13.9437 2.89679 13.6535 2.77172 13.3463 2.71771C13.0392 2.66371 12.7237 2.68228 12.425 2.77194L6.425 4.57194C6.01302 4.6956 5.65189 4.9488 5.39519 5.29394C5.13849 5.63909 4.99991 6.0578 5 6.48794V17.5119C4.99991 17.9421 5.13849 18.3608 5.39519 18.7059C5.65189 19.0511 6.01302 19.3043 6.425 19.4279Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="deconnexion-responsive">Déconnexion</span>
            </button>
            <div className="user">
              <Link to="/users" className={checkActivePage("/users")}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10Z"
                    fill={location.pathname === "/users" ? "white" : "#3953a4"}
                  />
                  <path
                    d="M20 17.5C20 19.985 20 22 12 22C4 22 4 19.985 4 17.5C4 15.015 7.582 13 12 13C16.418 13 20 15.015 20 17.5Z"
                    fill={location.pathname === "/users" ? "white" : "#3953a4"}
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <main className="mt-60">
          <Outlet />
        </main>
      </div>
    </>
  );
}
