import React, { useEffect, useState } from 'react';
import axios from "axios";
import ReportingAdminPage from "./Reporting_admin";
import ReportingUserPage from "./Reporting_user";

function Reporting() {

  const [role, setRole] = useState();
  const [loading, setLoading] = useState(true);

    // Envoyer une requête GET pour récupérer l'utilisateurs connecté
    useEffect(() => {
      const show = async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/user`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`, //authorization token pour récupérer l'utilisateurs
            },
          })
          .then((response) => {
            setRole(response.data.user.profil); //récupéré profil utilisateurs
            setLoading(false);
          })
          .catch((error) => {
            console.error(
              "Une erreur s'est produite lors du chargement des données:",
              error
            );
          });
      };
  
      show();
    }, []);

    if (loading) {
      return (
        <>
          <div className="loading">
            <div className="loader-wrapper">
              <div className="loader"></div>
            </div>
          </div>
        </>
      );
    }

const renderPage = () => {
  if (role === "ADM") {
    return <ReportingAdminPage />;
  } else if(role === "USR") {
    return <ReportingUserPage  />;
  }else {
    return '';
  }
};

  return renderPage();
}

export default Reporting;