import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

function ReportingUserPage() {
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState("");
  const [annee, setAnne] = useState(2024);
  const [usersId, setMembreId] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);

  //Admin role, récuperation de tous les utilisateurs
  const show = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
      .then((response) => {
        setSelectedUser(response.data.utilisateurs);
        setMembreId(response.data.utilisateurs.id);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du chargement des données:",
          error
        );
      });
  };
  useEffect(() => {
    show();
  }, []);

  if (loading) {
    return (
      <>
        <div className="loading">
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      </>
    );
  }

  //fuction etat membre
  function EtatImpotDons() {
    const page1Ref = useRef();
    const page2Ref = useRef();
    const [num, setNum] = useState("");
    const [stat, setStat] = useState("");
    const [montant, setMontant] = useState("");
    const [montantLettre, setMontantLettre] = useState("");

    const show_mt_don = async () => {
      if (usersId) {
        await axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/show_mt_don/${usersId}/${annee}`
          )
          .then((response) => {
            if (response.data.status === 200) {
              setMontant(response.data.montantTotal);
              setNum(response.data.num);
              setMontantLettre(response.data.Lettres);
              setStat("recucerfa");
            } else {
              setStat("error");
              console.log(response);
            }
          })
          .catch((error) => {
            console.error(error);
            if (error.response.data.status === 401) {
              setStat("nocotisation");
            } else {
              setStat("error");
              console.log(error);
            }
          });
      } else {
        console.log("UsersId non définit !");
      }
    };
    useEffect(() => {
      show_mt_don();
    }, []);

    const padNumber = (numero, length) => {
      return numero.toString().padStart(length, "0");
    };

    const exportToPDF = async () => {
      const doc = new jsPDF("p", "mm", "a4");
      //   const input = document.getElementById("A4pdf1");

      const addPageToPDF = async (element, doc) => {
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210; // A4 width in mm
        // const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        doc.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      };

      await addPageToPDF(page1Ref.current, doc);
      doc.addPage();
      await addPageToPDF(page2Ref.current, doc);

      doc.save(`${selectedUser.prenom}_cerfa_reçu.pdf`);
    };

    function RecuCERFA() {
      return (
        <>
          <div className="text-center title t-medium">
            Détail de votre reçu Cerfa pour l'ensemble des cotisations
            {annee === "2024" || annee === 2024
              ? " de cette année"
              : ` de l'année ${annee}`}
            :
          </div>
          <div className="bar mt-30"></div>
          <div className="btn-exporter">
            <button className="btn-param ml-5" onClick={() => exportToPDF()}>
              Exporter en PDF
            </button>
            <button
              className="btn-param revoque ml-5"
              onClick={() => handleActionChange("etatannee")}
            >
              Changer l'année
            </button>
          </div>
          <div className="bordered  mt-30">
            <div className="A4pdf1" ref={page1Ref}>
              <div className="header_recu mt-30">
                <div className="number_recu">
                  <div className="cerfa"></div>
                  <h2 className="mt-10">N° : 11580*03</h2>
                </div>
                <div className="title_recu">
                  <h1 className="text-center">
                    Reçu au titre des dons <br /> à certains organismes
                    d'intérêt général
                  </h1>
                  <p className="text-center mt-10">
                    Article 200, 238 bis et 885-0 V bis A du code général des
                    impôts (CGI)
                  </p>
                </div>
                <div className="number_recu mt-30 number-recu-p">
                  <p className="">
                    Numéro d'ordre du reçu <br /> {annee}-{padNumber(num, 4)}
                  </p>
                </div>
              </div>
              <div className="body_recu mt-30">
                <h2 className="underline">Bénéficiaire des versements :</h2>
                <p className="mt-20">
                  Nom ou dénomination :{" "}
                  <b>EGLISE PROTESTANTE MALGACHE EN FRANCE - FPMA VINCENNES</b>
                </p>
                <p className="mt-10">
                  Adresse : <b>N° 12, Rue Monmory</b>
                </p>
                <p className="mt-10">
                  Code postal : <b>94300</b>, Commune : <b>VINCENNES</b>
                </p>
                <h3 className="mt-20">
                  <span className="underline">Objet :</span>{" "}
                  <span className="objet">Assurer la célébration du culte</span>
                </h3>
                {/* <div className="bar mt-30"></div> */}
                <h3 className="underline mt-20">
                  Cochez la case concernée (1) :
                </h3>
                <p className="mt-20">
                  <input type="text" className="case" readOnly />
                  Association ou fondation reconnue d'utilité public par décret
                  en date du ...../..../...... publié au journal officiel du
                  ..../..../....... ou association située dans le département
                  de la Moselle, du Bas-Rhin ou du Haut-Rhin dans la mission a
                  été reconnue d'utilité public par arrêté préfectoral en date
                  du ..../..../...... .
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Fondation
                  universitaire ou fondation partenariale mentionnées
                  respectivement aux arcticles L. 719-12 et L. 719-13 du code de
                  l'éducation.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Fondation
                  d'entreprise
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Œuvre ou
                  organisme d'intérêt général.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Musée de
                  France.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Etablissement
                  d'enseignement supérieur ou d'enseignement artistique public
                  ou privé, d'intérêt général, à but non lucratif.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Organisme
                  ayant pour objet exclusif de participer financièrement à la
                  création d'entreprises.
                </p>
                <p className="mt-10">
                  <input type="checkbox" className="case" readOnly checked />{" "}
                  Association cultuelle ou de bienfaisance et établissement
                  public des cultes reconnus d'Alsace-Moselle.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Organisme
                  ayant pour activité principale l'organisation de festivals.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Association
                  fournissant gratuitement une aide alimentaire ou des soins
                  médicaux à des personnes en difficulté ou favorisant leur
                  logement
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Fondation du
                  patrimoine ou fondation ou association qui affecte
                  irrévocablement les Dons à la fondation du patrimoine, en vue
                  de subventionner les travaux prévus par les convention
                  conclues entre la fondation du patrimoine et les propriétaires
                  des immeubles (Article L. 143-2-1 du code du patrimoine)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Etablissement
                  de recherche public ou privé, d'intérêt général, à but non
                  lucratif.
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Entreprises
                  d'insertion ou entreprise de travail temporaire d'insertion
                  (Articles L. 5132-5 et L. 5132-6 du code de travail.)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Associations
                  intermédiaires (Article L. 5132-7 du code du travail
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Ateliers et
                  chantiers d'insertion (Article L. 5132-15 du code de travail)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Entreprises
                  adaptées (Article L. 5213-13 du code du travail.)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Agence
                  nationnal de la recherche (ANR)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Société ou
                  organisme agréé de recherche scientifique ou technique (2)
                </p>
                <p className="mt-10">
                  <input type="text" className="case" readOnly /> Autre
                  organisme :
                  ....................................................................
                </p>
                <div className="bar mt-20  bar-mini-text"></div>
                <div className="mini-text">
                  (1) ou n'indiquez que les renseignements concernant
                  l'organisme
                </div>
                <div className="mini-text">
                  (2) dons effectués par les entreprises
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bar mt-30"></div> */}
          <div className="bordered  mt-30">
            <div className="A4pdf1 mt-30" ref={page2Ref}>
              <h2 className="underline">Donateur :</h2>
              <p className="mt-30 donateur-p">
                Nom :{" "}
                <span className="uppercase mt-10 donateur">
                  {selectedUser.nom}
                </span>
              </p>
              <p className="mt-10">
                Prénoms :{" "}
                <span className="capitalize mt-10 donateur">
                  {selectedUser.prenom}
                </span>
              </p>
              <p className="mt-10">
                Adresse :{" "}
                <span className="capitalize mt-10 donateur">
                  {selectedUser.adr_voie}
                </span>
              </p>
              <p className="mt-10">
                Code postal :{" "}
                <span className="capitalize mt-10 donateur mr-80">
                  {selectedUser.adr_cp},
                </span>
                Commune :{" "}
                <span className="mt-10 donateur">{selectedUser.adr_ville}</span>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <p className="">
                Le bénéficiaire reconnait avoir reçu au titre des dons et
                versements ouvrant droit à réduction d'impôt, la somme de :
              </p>
              <div className="mt-30">
                <div className="input-impot">
                  {montant}
                  <b className="ml-20">€</b>
                </div>
              </div>
              <p className="mt-20">
                Somme en toutes lettres :{" "}
                <span className="ml-5">
                  <b className="capitalize">{montantLettre} Euros</b>
                </span>
              </p>
              <p className="mt-10">
                Date du versement ou du don : 31/12/{annee}
              </p>
              <p className="mt-10">
                Le bénéficiaire certifie sur l'honneur que les dons et
                versements qu'il reçoit ouvrent droit à la reduction d'impôt prévue à
                l'article (3) :{" "}
              </p>
              <p className="mt-20 paren-align-case">
                <span className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  200 du CGI
                </span>
                <span className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  238 Bis du CGI
                </span>
                <span className="align-case">
                  <input type="text" className="case" readOnly />
                  885-0 Bis A du CGI
                </span>
                <span className="align-case"></span>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <p className="mt-20 underline">
                <b>Forme du don :</b>
              </p>
              <p className="mt-20 paren-align-case">
                <span className="align-case">
                  <input type="text" className="case" readOnly />
                  Acte authentique
                </span>
                <span className="align-case">
                  <input type="text" className="case" readOnly />
                  Acte sous seing privé
                </span>
                <span className="align-case">
                  <input type="text" className="case" readOnly />
                  Déclaration de don manuel
                </span>
                <span className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Autres
                </span>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <p className="mt-20 underline">
                <b>Nature du don : </b>
              </p>
              <p className="mt-20 paren-align-case">
                <div className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Numéraire
                </div>
                <div className="align-case">
                  <input type="text" className="case" readOnly />
                  Titres des sociétés cotés
                </div>
                <div className="align-case">
                  <input type="text" className="case" readOnly />
                  Autres (4)
                </div>
                <div className="align-case"></div>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <p className="mt-20 underline">
                <b>En cas de don numéraire, mode de versement du don : </b>
              </p>
              <p className="mt-20 paren-align-case">
                <div className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Remise d'espèces
                </div>
                <div className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Chèque
                </div>
                <div className="align-case">
                  <input type="checkbox" className="case" readOnly checked />
                  Virement, prélèvement
                </div>
                <div className="align-case">Carte bancaire</div>
              </p>
              {/* <div className="bar mt-30"></div> */}
              <div className="mini-text">
                <span className="mr-20">(3)</span> L'organisme bénéficiaire peut
                cocher une ou plusieurs cases. <br /> L'organisme bénéficiaire
                peut, en application de l'article L. 80 C du livre des
                procédures fiscales, demander à l'administration s'il relève de
                l'une de cathégories d'organismes mentionnées aux articles 200
                et 238 bis du code général des impôts. <br /> Il est rappelé que
                la délivrance irrégulières de reçus fiscaux par l'organisme
                bénéficiaire est susceptible de donner lieu, en application des
                dispositions de l'article 1740 A du code général des impôts, à
                une amende fiscale égale à 25% des sommes indûment mentionnées
                sur ces documents.
              </div>
              <div className="mini-text mt-20 mini-text-4">
                <span className="mr-20">(4)</span> notament : abandon de revenus
                ou de produits ; frais engagés par les bénévoles, dont ils
                renoncent expréssement au remboursement.
              </div>
              <div className="signature">
                <div className="bloc-signature text-center">
                  <h3 className="">Date et signature</h3>
                  <p className="mt-20">31/12/{annee}</p>
                  <div className="img-signature mt-10"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-exporter mt-30">
            <button className="btn-param ml-5" onClick={() => exportToPDF()}>
              Exporter en PDF
            </button>
            <button
              className="btn-param revoque ml-5"
              onClick={() => handleActionChange("etatannee")}
            >
              Changer l'année
            </button>
          </div>
        </>
      );
    }

    function NoCotisation() {
      return (
        <>
          <div className="text-center title">
            Aucune cotisation pour{" "}
            {annee === "2024" || annee === 2024
              ? "cette année"
              : `l'année ${annee}`}{" "}
            !
          </div>
          <div className="btn-exporter">
            <button
              className="btn-param revoque ml-5"
              onClick={() => handleActionChange("etatannee")}
            >
              Changer l'année
            </button>
          </div>
        </>
      );
    }

    function Error() {
      return (
        <div className="text-center subtitle">
          Erreur s'est produit lors de la connexion à la base de donnée
        </div>
      );
    }

    //gestion pages avec button
    const renderFormAction = () => {
      switch (stat) {
        case "recucerfa":
          return <RecuCERFA />;
        case "nocotisation":
          return <NoCotisation />;
        case "error":
          return <Error />;
        default:
          return "";
      }
    };

    return renderFormAction();
  }

  function EtatAnnee() {
    const [searchAnnee, setSearchAnnee] = useState(2024);

    const handleSubmitAnne = async (ev) => {
      ev.preventDefault();
      setAnne(searchAnnee);
      console.log(annee);
      setAction("etat_impot");
    };

    return (
      <>
        <form onSubmit={handleSubmitAnne}>
          <p className="label-form mr-20">
            Sélectionnez l'année pour votre reçu Cerfa :
          </p>
          <input
            type="number"
            className="input-form input-data mt-10"
            min="1900"
            max="2300"
            value={searchAnnee}
            onChange={(e) => setSearchAnnee(e.target.value)}
            required
          />
          <button type="submit" className="btn-param ml-5">
            Valider
          </button>
        </form>
      </>
    );
  }

  const handleActionChange = (newAction) => {
    setAction(newAction);
  };

  //gestion pages avec button
  const renderForm = () => {
    switch (action) {
      case "etatannee":
        return <EtatAnnee />;
      case "etat_impot":
        return <EtatImpotDons />;
      default:
        return <EtatImpotDons />;
    }
  };

  return (
    <>
      <div className="container">
        <div className="container-lg">
          <div className="mt-30">{renderForm()}</div>
          <div className="bar mt-30"></div>
          <div className="mt-30 text-center">@ProjetAdidy</div>
        </div>
      </div>
    </>
  );
}

export default ReportingUserPage;
