import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../images/logo.png";
import axiosInstance from "./../axiosConfig";

function ForgotPassword() {
  const navigate = useNavigate();
  const [action, setAction] = useState("");
  const [login, setLogin] = useState("");
  const [loginId, setLoginId] = useState("");
  const [stat, setStat] = useState();

  useEffect(() => {
    if (stat === 200) {
      navigate("/login");
    }
  }, [stat, navigate]);

  function SelectEmail() {
    const loginRef = useRef();
    const [error, setError] = useState("");
    const onSubmit = async (ev) => {
      ev.preventDefault();

      const payload = {
        login: loginRef.current.value,
      };

      try {
        const response = await axiosInstance.post("/verifyemail", payload);
        console.log(response);
        setLogin(response.data.userLogin);
        setLoginId(response.data.userId);
        setAction("changpassword");
      } catch (error) {
        console.log(error);
        setLogin("");
        setError(error.response.data.error);
      }
    };

    return (
      <>
        <div className="containt-login-logo">
          <img src={logo} alt="Logo de l'eglise" className="login-logo" />
        </div>
        <form onSubmit={onSubmit}>
          <div className="block-label">
            <span className="obligatoire">*</span>
            <label htmlFor="login" className="block-label">
              Login :
            </label>
            <span className="error">{error}</span>
          </div>
          <div className="block">
            <input
              type="email"
              ref={loginRef}
              className="form-control"
              id="login"
              placeholder="Entrez votre email..."
              required
            />
          </div>
          <div className="block-button">
            <button type="submit" className="btn">
              Valider
            </button>
          </div>
          <div className="block-link">
            <div className="forgot-password">
              <Link to="/login"> Retour </Link>
            </div>
          </div>
        </form>
      </>
    );
  }

  function ChangePassword() {
    const [error2, setError2] = useState("");
    const passwordRef = useRef();
    const password_confirmationRef = useRef();

    const handleSubmit = async (ev) => {
      ev.preventDefault();
      const utilisateurs = {
        password: passwordRef.current.value,
        password_confirmation: password_confirmationRef.current.value,
      };

      try {
        const response = await axiosInstance.post(
          `/change_password/${loginId}`,
          utilisateurs
        );
        console.log(response);
        setStat(response.data.status);
      } catch (error) {
        console.log(error);
        setError2(error.response.data.errors.password);
      }
    };
    
      const handleClick = () => {
        setError2('');
      };

    return (
      <>
        <div className="containt-login-logo">
          <img src={logo} alt="Logo de l'eglise" className="login-logo" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="block-label">
            <span className="obligatoire">*</span>
            <label htmlFor="login" className="block-label">
              Login :
            </label>
          </div>
          <div className="block">
            <input
              type="email"
              className="form-control"
              value={login}
              readOnly
            />
          </div>
          <div className="block-label">
            <span className="obligatoire">*</span>
            <label htmlFor="login" className="block-label">
              Mot de passe :
            </label>
            <span className="error">{!error2 ? '' : "Mot de passe invalid !"}</span>
          </div>
          <div className="block">
            <input
              type="password"
              ref={passwordRef}
              className="form-control"
              id="password"
              placeholder="Entrez votre mot de passe..."
              onClick={handleClick}
              required
            />
          </div>
          <div className="block-label">
            <span className="obligatoire">*</span>
            <label htmlFor="login" className="block-label">
              Confirmez le mot de passe :
            </label>
            <span className="error">{!error2 ? '' : "Mot de passe invalid !"}</span>
          </div>
          <div className="block">
            <input
              type="password"
              ref={password_confirmationRef}
              className="form-control"
              id="confirm_password"
              placeholder="Confirmez votre mot de passe..."
              onClick={handleClick}
              required
            />
          </div>
          <p className={!error2 ? 'display-none' : "sous-error"}>Mot de passe : 6+ caractères, 1 majuscule !</p>
          <div className="block-button">
            <button type="submit" className="btn">
              Modifier
            </button>
            <button type="button" className="btn revoque ml-5" onClick={() => {
              navigate("/login")
            }}>
              Annuler
            </button>
          </div>
          <div className="block-link">
            <div className="forgot-password">
              {/* <Link to="/login"> Retour </Link> */}
            </div>
          </div>
        </form>
      </>
    );
  }
  //gestion pages avec button
  const renderForm = () => {
    switch (action) {
      case "selectemail":
        return <SelectEmail />;
      case "changpassword":
        return <ChangePassword />;
      default:
        return <SelectEmail />;
    }
  };

  return (
    <>
      <div className="container-xxl">
        <div className="container-login">{renderForm()}</div>
      </div>
    </>
  );
}

export default ForgotPassword;
