import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../Context/ContextProvider";
import logo from "./../images/logo.png";
import axiosInstance from "./../axiosConfig";

export default function Login() {
  const loginRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState();

  const { setUser, setToken } = useStateContext();

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const payload = {
      login: loginRef.current.value,
      password: passwordRef.current.value,
    };

    try {
      const response = await axiosInstance.post("/login", payload);
      if (response.data.status === 200) {
        console.log(response);
        setUser(response.data.user);
        setToken(response.data.token);
      } else {
        setError(response.data.msg);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      console.log(payload);
    }
  };

  return (
    <>
      <div className="container-xxl">
        <div className="container-login">
          <div className="containt-login-logo">
            <img src={logo} alt="Logo de l'eglise" className="login-logo" />
          </div>
          <form onSubmit={onSubmit}>
            <div className="block-label">
              <span className="obligatoire">*</span>
              <label htmlFor="login" className="block-label">
                Nom d'utilisateur :
              </label>
              <span className="error">{error}</span>
            </div>
            <div className="block">
              <input
                type="email"
                ref={loginRef}
                className="form-control"
                id="login"
                placeholder="Entrez votre email..."
                required
              />
            </div>
            <div className="block-label">
              <span className="obligatoire">*</span>
              <label htmlFor="password" className="block-label">
                Mot de passe :
              </label>
              <span className="error">{error}</span>
            </div>
            <div className="block">
              <input
                type="password"
                ref={passwordRef}
                className="form-control"
                id="password"
                placeholder="Mot de passe"
                required
              />
            </div>
            <div className="block-button">
              <button type="submit" className="btn">
                Se connecter
              </button>
            </div>
            <div className="block-link">
              <div className="forgot-password">
                Mot de passe oublié ?{" "}
                <Link to="/forgotpassword"> Cliquez ici</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}