import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import { format } from "date-fns";

function CotisationAdminPage() {
  const [action, setAction] = useState(null);
  const [cotisation, setCotisation] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clickedA, setclickedAdidy] = useState(false);
  const [clickedD, setclickedDon] = useState(false);
  const [clickedS, setclickedSous] = useState(false);
  const [clickedESP, setclickedESP] = useState(false);
  const [clickedVIR, setclickedVIR] = useState(false);
  const [clickedCHE, setclickedCHE] = useState(false);
  const [clickedCB, setclickedCB] = useState(false);

  // const [errorMessage, setErrorMessage] = useState("");
  const [activeInput, setActiveInput] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchMatricule, setSearchMatricule] = useState("");
  const [searchAnnee, setSearchAnnee] = useState(
    new Date().getFullYear().toString()
  );
  const [sercheTyp_pmt, setsearchTyp_pmt] = useState("");
  const [sercheMode_pmt, setsearchMode_pmt] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const cotisationPerPage = 8;

  useEffect(() => {
    const show_cotisation = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/show_cotisation`
        );
        setCotisation(response.data.cotisation);
        setLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors du chargement des données:",
          error
        );
      }
    };
    show_cotisation();
  }, []);

  if (loading) {
    return (
      <>
        <div className="loading">
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      </>
    );
  }

  // Gestionnaire d'événements pour la modification de la cotisation sélectionnée
  function handleInputChange(event) {
    const userId = event.target.value;
    setAction("checked");

    setActiveInput("active");

    // Recherche de cotisation par son ID
    const selectedUser = cotisation.find(
      (user) => user.id === parseInt(userId)
    );

    // Vérifier si la cotisation sélectionnée existe dans les utilisateurs
    if (selectedUser) {
      setSelectedUser(selectedUser);
    }
  }

  function AjouterCotisation() {
    const [error, setError] = useState(false);

    const id_mbrRef = useRef();
    const dat_pmtRef = useRef();
    const typ_mtRef = useRef();
    const mod_pmtRef = useRef();
    const mtRef = useRef();
    const notesRef = useRef();
    const id_recuRef = useRef();
    const aa_debRef = useRef();
    const aa_finRef = useRef();
    const mm_debRef = useRef();
    const mm_finRef = useRef();

    const handleSubmit = async (ev) => {
      ev.preventDefault();
      const cotisations = {
        id_mbr: parseInt(id_mbrRef.current.value, 10),
        dat_pmt: dat_pmtRef.current.value,
        typ_mt: typ_mtRef.current.value,
        mod_pmt: mod_pmtRef.current.value,
        mt: parseFloat(mtRef.current.value),
      };

      // Vérifier si la note est renseignée
      if (notesRef.current.value !== "") {
        cotisations.notes = notesRef.current.value;
      } else {
        cotisations.notes = "Vide";
      }
      // Vérifier si l'identification du reçu bancaire est renseignée
      if (id_recuRef.current.value !== "") {
        cotisations.id_recu = id_recuRef.current.value;
      } else {
        cotisations.id_recu = "Vide";
      }

      // Vérifier si l'année Deb est renseignée
      if (aa_debRef.current.value !== "") {
        cotisations.aa_deb = parseInt(aa_debRef.current.value, 10);
      } else {
        const dateValue = dat_pmtRef.current.value;
        const dateObject = new Date(dateValue);
        cotisations.aa_deb = dateObject.getFullYear();
      }

      // Vérifier si l'année fin est renseignée
      if (aa_finRef.current.value !== "") {
        cotisations.aa_fin = parseInt(aa_finRef.current.value, 10);
      } else {
        const dateValue = dat_pmtRef.current.value;
        const dateObject = new Date(dateValue);
        cotisations.aa_fin = dateObject.getFullYear();
      }

      // Vérifier si mois deb est renseignée
      if (mm_debRef.current.value !== "") {
        cotisations.mm_deb = parseInt(mm_debRef.current.value, 10);
      } else {
        const dateValue = dat_pmtRef.current.value;
        const dateObject = new Date(dateValue);
        cotisations.mm_deb = dateObject.getMonth() + 1;
      }

      // Vérifier si mois fin est renseignée
      if (mm_finRef.current.value !== "") {
        cotisations.mm_fin = parseInt(mm_finRef.current.value, 10);
      } else {
        const dateValue = dat_pmtRef.current.value;
        const dateObject = new Date(dateValue);
        cotisations.mm_fin = dateObject.getMonth() + 1;
      }

      // requête pour ajouter une cotisation
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/add_cotisation`,
          cotisations
        )
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          setError(error.response.status);
          console.log(error);
          console.log("Requete non executer");
        });
    };

    return (
      <>
        <div className="container">
          <div className="container-form-membre">
            <h2 className="title">Ajouter une Cotisation</h2>
            <form id="monFormulaire" onSubmit={handleSubmit}>
              <div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Matricule :</label>
                      <span className="error">
                        {error !== 401 ? "" : "Id membre inconnu !"}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="number"
                        className="input-form"
                        ref={id_mbrRef}
                        placeholder="Entrez le matricule..."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Date de paiement :
                      </label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="date"
                        className="input-form"
                        ref={dat_pmtRef}
                        placeholder="Date de paiement..."
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Type de paiement :
                      </label>
                    </div>
                    <div className="block-form">
                      <select className="input-form" ref={typ_mtRef} required>
                        <option value="">Type de paiement...</option>
                        <option value="A">Adidy</option>
                        <option value="D">Don</option>
                        <option value="S">Souscription</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Mode de paiement :
                      </label>
                    </div>
                    <div className="block-form">
                      <select className="input-form" ref={mod_pmtRef} required>
                        <option value="">Mode de paiement...</option>
                        <option value="ESP">Espèces</option>
                        <option value="CHE">Chèque</option>
                        <option value="VIR">Virement</option>
                        <option value="CB">Carte bleue</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="block-label-membre">
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Montant (en €):
                      </label>
                      <span className="error">
                        {/* {!error ? "" : <p>Email existe déjà !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="number"
                        step="0.01"
                        className="input-form"
                        ref={mtRef}
                        placeholder="000,00..."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <label className="block-label-membre">
                        Identifiant souche reçu :
                      </label>
                      <span className="error">
                        {error !== 422 ? "" : <p>ID limité à 5 caractères !</p>}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        ref={id_recuRef}
                        placeholder="Identifiant souche reçu..."
                      />
                    </div>
                  </div>
                </div>
                <div className="row-lg">
                  <div className="col-lg-6">
                    <div className="block-mini-input">
                      <div className="mini-input">
                        <div>
                          <label className="block-label-membre">
                            Mois début :
                          </label>
                          <span className="error">
                            {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                          </span>
                        </div>
                        <div className="block-form">
                          <input
                            type="number"
                            className="input-form"
                            min="1"
                            max="12"
                            ref={mm_debRef}
                            placeholder="Mois début..."
                          />
                        </div>
                      </div>
                      <div className="mini-input">
                        <div>
                          <label className="block-label-membre">
                            Année début :
                          </label>
                          <span className="error">
                            {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                          </span>
                        </div>
                        <div className="block-form">
                          <input
                            type="number"
                            min="1900"
                            max="2300"
                            className="input-form"
                            ref={aa_debRef}
                            placeholder="Année début..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="block-mini-input mt-30">
                      <div className="mini-input">
                        <div>
                          <label className="block-label-membre">
                            Mois fin :
                          </label>
                          <span className="error">
                            {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                          </span>
                        </div>
                        <div className="block-form">
                          <input
                            type="number"
                            min="1"
                            max="12"
                            className="input-form"
                            ref={mm_finRef}
                            placeholder="Mois fin..."
                          />
                        </div>
                      </div>
                      <div className="mini-input">
                        <div>
                          <label className="block-label-membre">
                            Année fin :
                          </label>
                          <span className="error">
                            {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                          </span>
                        </div>
                        <div className="block-form">
                          <input
                            type="number"
                            min="1900"
                            max="2300"
                            className="input-form"
                            ref={aa_finRef}
                            placeholder="Année fin..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div>
                      <label className="block-label-membre">Notes :</label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="data-list-culum">
                      <div className="champ-member-input textarea-sm">
                        <textarea
                          maxlength="30"
                          name="notes"
                          ref={notesRef}
                          className="input-form mt-5 textarea w-420"
                          placeholder="Notes..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn">
                Ajouter
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  function ModifierCotisation() {
    const [error, setError] = useState(false);

    const id_mbrRef = useRef();
    const dat_pmtRef = useRef();
    const typ_mtRef = useRef();
    const mod_pmtRef = useRef();
    const mtRef = useRef();
    const notesRef = useRef();
    const id_recuRef = useRef();
    const aa_debRef = useRef();
    const aa_finRef = useRef();
    const mm_debRef = useRef();
    const mm_finRef = useRef();

    const handleSubmit = async (ev) => {
      ev.preventDefault();
      const cotisations = {
        id_mbr: parseInt(id_mbrRef.current.value, 10),
        dat_pmt: dat_pmtRef.current.value,
        typ_mt: typ_mtRef.current.value,
        mod_pmt: mod_pmtRef.current.value,
        mt: parseInt(mtRef.current.value, 10),
      };

      // Vérifier si la note est renseignée
      if (notesRef.current.value !== "") {
        cotisations.notes = notesRef.current.value;
      } else {
        cotisations.notes = "Vide";
      }
      // Vérifier si l'identification du reçu bancaire est renseignée
      if (id_recuRef.current.value !== "") {
        cotisations.id_recu = id_recuRef.current.value;
      } else {
        cotisations.id_recu = "Vide";
      }

      // Vérifier si l'année Deb est renseignée
      if (aa_debRef.current.value !== "") {
        cotisations.aa_deb = parseInt(aa_debRef.current.value, 10);
      } else {
        const dateValue = dat_pmtRef.current.value;
        const dateObject = new Date(dateValue);
        cotisations.aa_deb = dateObject.getFullYear();
      }

      // Vérifier si l'année fin est renseignée
      if (aa_finRef.current.value !== "") {
        cotisations.aa_fin = parseInt(aa_finRef.current.value, 10);
      } else {
        const dateValue = dat_pmtRef.current.value;
        const dateObject = new Date(dateValue);
        cotisations.aa_fin = dateObject.getFullYear();
      }

      // Vérifier si mois deb est renseignée
      if (mm_debRef.current.value !== "") {
        cotisations.mm_deb = parseInt(mm_debRef.current.value, 10);
      } else {
        const dateValue = dat_pmtRef.current.value;
        const dateObject = new Date(dateValue);
        cotisations.mm_deb = dateObject.getMonth() + 1;
      }

      // Vérifier si mois fin est renseignée
      if (mm_finRef.current.value !== "") {
        cotisations.mm_fin = parseInt(mm_finRef.current.value, 10);
      } else {
        const dateValue = dat_pmtRef.current.value;
        const dateObject = new Date(dateValue);
        cotisations.mm_fin = dateObject.getMonth() + 1;
      }

      // requête pour Modifier une cotisation
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/modifier_cotisation/${selectedUser.id}`,
          cotisations
        )
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          setError(error);
          console.log(error);
          console.log("Requete non executer");
        });
    };

    return (
      <>
        <div className="container">
          <div className="container-form-membre">
            <h2 className="title">Modifier une Cotisation</h2>
            <form id="monFormulaire" onSubmit={handleSubmit}>
              <div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Matricule :</label>
                      <span className="error">
                        {!error ? "" : error.response.data.error}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="number"
                        className="input-form"
                        ref={id_mbrRef}
                        defaultValue={selectedUser.id_mbr}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Date de paiement :
                      </label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="date"
                        className="input-form"
                        ref={dat_pmtRef}
                        value={selectedUser.dat_pmt}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            dat_pmt: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Type de paiement :
                      </label>
                    </div>
                    <div className="block-form">
                      <select className="input-form" ref={typ_mtRef} required>
                        <option value={selectedUser.typ_mt}>
                          {selectedUser.typ_mt === "A"
                            ? "Adidy"
                            : selectedUser.typ_mt === "D"
                            ? "Don"
                            : selectedUser.typ_mt === "S"
                            ? "Souscription"
                            : ""}
                        </option>
                        <option
                          className={
                            selectedUser.typ_mt === "A" ? "display-none" : ""
                          }
                          value="A"
                        >
                          Adidy
                        </option>
                        <option
                          className={
                            selectedUser.typ_mt === "D" ? "display-none" : ""
                          }
                          value="D"
                        >
                          Don
                        </option>
                        <option
                          className={
                            selectedUser.typ_mt === "S" ? "display-none" : ""
                          }
                          value="S"
                        >
                          Souscription
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Mode de paiement :
                      </label>
                    </div>
                    <div className="block-form">
                      <select className="input-form" ref={mod_pmtRef} required>
                        <option value={selectedUser.mod_pmt}>
                          {selectedUser.mod_pmt === "ESP"
                            ? "Espèce"
                            : selectedUser.mod_pmt === "VIR"
                            ? "Virement"
                            : selectedUser.mod_pmt === "CB"
                            ? "Carte Bleue"
                            : selectedUser.mod_pmt === "CHE"
                            ? "Chèque"
                            : ""}
                        </option>
                        <option
                          className={
                            selectedUser.mod_pmt === "ESP" ? "display-none" : ""
                          }
                          value="ESP"
                        >
                          Espèces
                        </option>
                        <option
                          className={
                            selectedUser.mod_pmt === "CHE" ? "display-none" : ""
                          }
                          value="CHE"
                        >
                          Chèque
                        </option>
                        <option
                          className={
                            selectedUser.mod_pmt === "VIR" ? "display-none" : ""
                          }
                          value="VIR"
                        >
                          Virement
                        </option>
                        <option
                          className={
                            selectedUser.mod_pmt === "CB" ? "display-none" : ""
                          }
                          value="CB"
                        >
                          Carte bleue
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="block-label-membre">
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Montant (en €):
                      </label>
                      <span className="error">
                        {/* {!error ? "" : <p>Email existe déjà !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="number"
                        step="0.01"
                        className="input-form"
                        ref={mtRef}
                        defaultValue={selectedUser.mt}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <label className="block-label-membre">
                        Identifiant souche reçu :
                      </label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        ref={id_recuRef}
                        defaultValue={selectedUser.id_recu}
                      />
                    </div>
                  </div>
                </div>
                <div className="row-lg">
                  <div className="col-lg-6">
                    <div className="block-mini-input">
                      <div className="mini-input">
                        <div>
                          <label className="block-label-membre">
                            Mois début :
                          </label>
                          <span className="error">
                            {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                          </span>
                        </div>
                        <div className="block-form">
                          <input
                            type="number"
                            min="1"
                            max="12"
                            className="input-form"
                            ref={mm_debRef}
                            defaultValue={selectedUser.mm_deb}
                          />
                        </div>
                      </div>
                      <div className="mini-input">
                        <div>
                          <label className="block-label-membre">
                            Année début :
                          </label>
                          <span className="error">
                            {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                          </span>
                        </div>
                        <div className="block-form">
                          <input
                            type="number"
                            min="1900"
                            max="2300"
                            className="input-form"
                            ref={aa_debRef}
                            defaultValue={selectedUser.aa_deb}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="block-mini-input mt-30">
                      <div className="mini-input">
                        <div>
                          <label className="block-label-membre">
                            Mois fin :
                          </label>
                          <span className="error">
                            {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                          </span>
                        </div>
                        <div className="block-form">
                          <input
                            type="number"
                            min="1"
                            max="12"
                            className="input-form"
                            ref={mm_finRef}
                            defaultValue={selectedUser.mm_fin}
                          />
                        </div>
                      </div>
                      <div className="mini-input">
                        <div>
                          <label className="block-label-membre">
                            Année fin :
                          </label>
                          <span className="error">
                            {/* {error !== 400 ? "" : <p>Login inconnu !</p>} */}
                          </span>
                        </div>
                        <div className="block-form">
                          <input
                            type="number"
                            className="input-form"
                            ref={aa_finRef}
                            defaultValue={selectedUser.aa_fin}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div>
                      <label className="block-label-membre">Notes :</label>
                      <span className="error">
                        {/* {error !== 401 ? "" : <p>Mot de passe invalid !</p>} */}
                      </span>
                    </div>
                    <div className="data-list-culum">
                      <div className="champ-member-input textarea-sm">
                        <textarea
                          maxlength="30"
                          name="notes"
                          ref={notesRef}
                          defaultValue={selectedUser.notes}
                          className="input-form mt-5 textarea w-420"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn">
                Modifier
              </button>
              <button
                onClick={() => {
                  setAction("checked");
                }}
                className="btn revoque ml-5"
              >
                Annuler
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  function DataCotisationChecked() {
    return (
      <>
        <div className="block-liste-xsm mt-30">
          <div className="liste-title">
            <p>MEMBRES</p>
          </div>
          <div className="ref-cotisation">
            <h4 className="ref-data">Matricule : {selectedUser.id_mbr}</h4>
            <h4 className="ref-data">Nom : {selectedUser.nom}</h4>
            <h4 className="ref-data responsive-600">Prénom(s) : {selectedUser.prenom}</h4>
          </div>
          <div className="liste-data-culum">
            <div className="liste-data-culum-grid">
              <div className="data-list-culum">
                <div className="champ-member-input mt-20">
                  <label className="label-form">Date de paiement:</label>
                  <input
                    type="text"
                    name="name"
                    className="input-form mt-10"
                    value={
                      !selectedUser.dat_pmt
                        ? ""
                        : format(new Date(selectedUser.dat_pmt), "dd-MM-yyyy")
                    }
                    readOnly
                  />
                </div>
                <div className="champ-member-input">
                  <label className="label-form">Type de paiement :</label>
                  <input
                    type="text"
                    name="name"
                    className="input-form mt-10"
                    value={
                      selectedUser.typ_mt === "A"
                        ? "Adidy"
                        : selectedUser.typ_mt === "D"
                        ? "Don"
                        : selectedUser.typ_mt === "S"
                        ? "Souscription"
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="data-list-culum">
                <div className="champ-member-input mt-20">
                  <label className="label-form">Montant : (en €)</label>
                  <input
                    type="text"
                    name="name"
                    className="input-form mt-10"
                    value={selectedUser.mt}
                    readOnly
                  />
                </div>
                <div className="champ-member-input">
                  <label className="label-form">Mode de paiement :</label>
                  <input
                    type="text"
                    name="name"
                    className="input-form mt-10"
                    value={
                      selectedUser.mod_pmt === "VIR"
                        ? "Virement"
                        : selectedUser.mod_pmt === "CHE"
                        ? "Chèque"
                        : selectedUser.mod_pmt === "ESP"
                        ? "Espèce"
                        : selectedUser.mod_pmt === "CB"
                        ? "Carte bleue"
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="data-list-culum">
                <div className="champ-member-input mt-20">
                  <label className="label-form">Notes :</label>
                  <textarea
                    name="name"
                    className="input-form mt-10 textarea"
                    value={selectedUser.notes}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  //Fonction pour exporter tous
  const tousExporter = (e) => {
    e.preventDefault();

    // Vérifier si des utilisateurs sont disponibles
    if (!cotisation || cotisation.length === 0) {
      console.error("Aucun utilisateur à exporter.");
      return;
    }

    // Créer les données pour l'utilisateur sélectionné
    const data = cotisation.map((user) => ({
      Matricule: user.id_mbr,
      Nom: user.nom,
      Prénom: user.prenom,
      Mode_paiement: user.mod_pmt,
      Montant: user.mt,
      Date_paiement: format(new Date(user.dat_pmt), "dd-MM-yyyy"),
      Type_paiement: user.typ_mt,
      Notes: user.notes,
      Id_recu: user.id_recu,
      Mois_deb: user.mm_deb,
      Mois_fin: user.mm_fin,
      Ann_deb: user.aa_deb,
      Ann_fin: user.aa_fin,
    }));

    // Créer une feuille de calcul
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Cotisations");

    // Générer le fichier en mémoire
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Convertir le fichier en blob
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    // Télécharger le fichier
    saveAs(blob, "cotisations.xlsx");
  };

  // Fonction pour convertir une chaîne en tableau de bytes
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  //Exporter un utilisateurs
  const oneExporter = (e) => {
    e.preventDefault();

    // Vérifier si un utilisateur est sélectionné
    if (!selectedUser) {
      console.error("Aucun utilisateur sélectionné.");
      return;
    }

    // Formatage de la date selon le format DD-MM-YYYY
    let dateFormatee = format(new Date(selectedUser.dat_pmt), "dd-MM-yyyy");

    // Créer les données pour l'utilisateur sélectionné
    const data = [
      {
        Matricule: selectedUser.id_mbr,
        Nom: selectedUser.nom,
        Prénom: selectedUser.prenom,
        Mode_paiement: selectedUser.mod_pmt,
        Montant: selectedUser.mt,
        Date_paiement: dateFormatee,
        Type_paiement: selectedUser.typ_mt,
        Notes: selectedUser.notes,
        Id_recu: selectedUser.id_recu,
        Mois_deb: selectedUser.mm_deb,
        Mois_fin: selectedUser.mm_fin,
        Ann_deb: selectedUser.aa_deb,
        Ann_fin: selectedUser.aa_fin,
      },
    ];

    // Créer une feuille de calcul
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Cotisations");

    // Sauvegarder le fichier
    const fileName = `${selectedUser.prenom}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  //gestion pages avec button
  const renderForm = () => {
    switch (action) {
      case "ajouter":
        return <AjouterCotisation />;
      case "modifier":
        return <ModifierCotisation />;
      case "checked":
        return <DataCotisationChecked />;
      default:
        return <DataCotisationChecked />;
    }
  };

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Utiliser la liste filtrée si une recherche a été effectuée, sinon utiliser la liste complète
  const filteredCotisation = searchPerformed
    ? cotisation.filter((user) => {
        const nameMatch = user.nom
          .toLowerCase()
          .includes(searchName.toLowerCase());
        const firstNameMatch = user.prenom
          .toLowerCase()
          .includes(searchFirstName.toLowerCase());
        const matriculeMatch = user.id_mbr
          .toString()
          .toLowerCase()
          .includes(searchMatricule.toLowerCase());
        const anneeMatch = new Date(user.dat_pmt)
          .getFullYear()
          .toString()
          .toLowerCase()
          .includes(searchAnnee.toLowerCase());
        const typ_pmt = user.typ_mt
          .toString()
          .toLowerCase()
          .includes(sercheTyp_pmt.toLowerCase());
        const mode_pmt = user.mod_pmt
          .toString()
          .toLowerCase()
          .includes(sercheMode_pmt.toLowerCase());
        return (
          nameMatch &&
          firstNameMatch &&
          matriculeMatch &&
          anneeMatch &&
          typ_pmt &&
          mode_pmt
        );
      })
    : cotisation;

  const indexOfLastUser = currentPage * cotisationPerPage;
  const indexOfFirstUser = indexOfLastUser - cotisationPerPage;
  const currentCotisation = cotisation
    ? filteredCotisation.slice(indexOfFirstUser, indexOfLastUser)
    : [];

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchPerformed(true); // Indique qu'une recherche a été effectuée
    setCurrentPage(1); // Réinitialiser la pagination à la première page lors de la recherche
  };

  const handleClick = (state, setState) => {
    // Inverser la valeur de l'état lors du clic
    setState((prevState) => !prevState);
  };

  return (
    <>
      <div className="container">
        <div className="container-lg">
          <div className="block-liste block-liste-cherche block-liste-cherche-lg mt-30">
            <div className="liste-title">
              <p>RECHERCHE</p>
            </div>
            <form onSubmit={handleSearch}>
              <div className="liste-recherche liste-recherche-cotisation">
                <div className="champ-cherche champ-cherche-lg">
                  <div>
                    <label className="label-form">Matricule : </label>
                    <input
                      type="number"
                      name="matricule"
                      className="input-form mt-10 input-form-search-responsive"
                      value={searchMatricule}
                      onChange={(e) => setSearchMatricule(e.target.value)}
                      placeholder="Matricule à rechercher..."
                    />
                  </div>
                  <div>
                    <label className="label-form">Nom : </label>
                    <input
                      type="text"
                      name="name"
                      className="input-form mt-10 input-form-search-responsive"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      placeholder="Nom à rechercher..."
                    />
                  </div>
                </div>
                <div className="champ-cherche champ-cherche-lg">
                  <div>
                    <label className="label-form">Prénom(s) : </label>
                    <input
                      type="text"
                      name="lastname"
                      className="input-form mt-10 input-form-search-responsive"
                      value={searchFirstName}
                      onChange={(e) => setSearchFirstName(e.target.value)}
                      placeholder="Prénom à rechercher..."
                    />
                  </div>
                  <div>
                    <label className="label-form">Année : </label>
                    <input
                      type="number"
                      name="yearInput"
                      className="input-form mt-10 input-form-search-responsive"
                      value={searchAnnee}
                      onChange={(e) => setSearchAnnee(e.target.value)}
                    />
                  </div>
                </div>
                <div className="champ-cherche champ-cherche-sm">
                  <div>
                    <label className="label-form"></label>
                    <div className="">
                      <button type="submit" className="btn-sm ml-5">
                        Rechercher
                      </button>
                      <button
                        type="button"
                        className="btn-sm ml-5"
                        onClick={() => {
                          setSearchName("");
                          setSearchFirstName("");
                          setSearchPerformed(false);
                          setAction("");
                          setSearchMatricule("");
                          setclickedAdidy("");
                          setclickedDon("");
                          setclickedSous("");
                          setclickedESP("");
                          setclickedVIR("");
                          setclickedCHE("");
                          setclickedCB("");
                          setSearchAnnee(new Date().getFullYear().toString());
                          setSelectedUser([]);
                          setsearchMode_pmt("");
                          setsearchTyp_pmt("");
                          setActiveInput("");
                          // Réinitialiser la sélection de l'utilisateur
                          const radioButtons = document.querySelectorAll(
                            'input[type="radio"][name="selectedUser"]'
                          );
                          radioButtons.forEach((button) => {
                            button.checked = false;
                          });
                        }}
                      >
                        Réinitialiser
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="liste-recherche-px">
                <div className="champ-cherche">
                  <label className="label-form">Type cotisation : </label>
                  <div className="btn-filter">
                    <button
                      type=""
                      className={
                        sercheTyp_pmt === "A"
                          ? "radio-binary radio-binary-lg cheked"
                          : "radio-binary radio-binary-lg"
                      }
                      onClick={() => {
                        handleClick(clickedA, setclickedAdidy);
                        setclickedDon("");
                        setclickedSous("");
                        setsearchTyp_pmt("A");
                      }}
                    >
                      A
                    </button>
                    <button
                      type=""
                      className={
                        sercheTyp_pmt === "D"
                          ? "radio-binary radio-binary-lg cheked"
                          : "radio-binary radio-binary-lg"
                      }
                      onClick={() => {
                        handleClick(clickedD, setclickedDon);
                        setclickedAdidy("");
                        setclickedSous("");
                        setsearchTyp_pmt("D");
                      }}
                    >
                      D
                    </button>
                    <button
                      type=""
                      className={
                        sercheTyp_pmt === "S"
                          ? "radio-binary radio-binary-lg cheked"
                          : "radio-binary radio-binary-lg"
                      }
                      onClick={() => {
                        handleClick(clickedS, setclickedSous);
                        setclickedAdidy("");
                        setclickedDon("");
                        setsearchTyp_pmt("S");
                      }}
                    >
                      S
                    </button>
                  </div>
                </div>
                <div className="champ-cherche champ-cherche-4">
                  <label className="label-form">Mode paiement : </label>
                  <div className="btn-filter">
                    <button
                      type=""
                      className={
                        sercheMode_pmt === "ESP"
                          ? "radio-binary radio-binary-lg cheked"
                          : "radio-binary radio-binary-lg"
                      }
                      onClick={() => {
                        handleClick(clickedESP, setclickedESP);
                        setclickedCHE("");
                        setclickedVIR("");
                        setclickedCB("");
                        setsearchMode_pmt("ESP");
                      }}
                    >
                      ESP
                    </button>
                    <button
                      type=""
                      className={
                        sercheMode_pmt === "CHE"
                          ? "radio-binary radio-binary-lg cheked"
                          : "radio-binary radio-binary-lg"
                      }
                      onClick={() => {
                        handleClick(clickedCHE, setclickedCHE);
                        setclickedESP("");
                        setclickedVIR("");
                        setclickedCB("");
                        setsearchMode_pmt("CHE");
                      }}
                    >
                      CHE
                    </button>
                    <button
                      type=""
                      className={
                        sercheMode_pmt === "VIR"
                          ? "radio-binary radio-binary-lg cheked"
                          : "radio-binary radio-binary-lg"
                      }
                      onClick={() => {
                        handleClick(clickedVIR, setclickedVIR);
                        setclickedCHE("");
                        setclickedESP("");
                        setclickedCB("");
                        setsearchMode_pmt("VIR");
                      }}
                    >
                      VIR
                    </button>
                    <button
                      type=""
                      className={
                        sercheMode_pmt === "CB"
                          ? "radio-binary radio-binary-lg cheked"
                          : "radio-binary radio-binary-lg"
                      }
                      onClick={() => {
                        handleClick(clickedCB, setclickedCB);
                        setclickedCHE("");
                        setclickedVIR("");
                        setclickedESP("");
                        setsearchMode_pmt("CB");
                      }}
                    >
                      CB
                    </button>
                  </div>
                </div>
                <div className="champ-cherche champ-cherche-sm responsive-1200">
                  <div></div>
                </div>
              </div>
            </form>
          </div>
          <div className="bar mt-30"></div>
          <div className="block-liste">
            <div className="liste-title">
              <p>LISTE DES COTISATIONS</p>
            </div>
            <div className="liste-containt">
              <div className="table">
                <div className="theader">
                  <div className="check">
                    <div className="box"></div>
                  </div>
                  <div className="w-100">Matricule</div>
                  <div className="w-200">Nom</div>
                  <div className="w-200 responsive-768">Prénom</div>
                  <div className="w-100">Montant</div>
                  <div className="w-100 responsive-600">Type Cot.</div>
                  <div className="w-100 responsive-600">Mode Pmt</div>
                  <div className="w-100 responsive-1200">Date Pmt</div>
                  <div className="w-300 responsive-998">Notes</div>
                </div>
                <div className="tbody">
                  {currentCotisation.map((user) => (
                    <div key={user.id} className="liste-value">
                      <div>
                        <input
                          className="check check-box"
                          type="radio"
                          name="selectedUser"
                          value={user.id}
                          onChange={(event) => handleInputChange(event)}
                        />
                      </div>
                      <div className="w-100">{user.id_mbr}</div>
                      <div className="w-200">{user.nom}</div>
                      <div className="w-200 responsive-768">{user.prenom}</div>
                      <div className="w-100">{user.mt}</div>
                      <div className="w-100 responsive-600">{user.typ_mt}</div>
                      <div className="w-100 responsive-600">{user.mod_pmt}</div>
                      <div className="w-100 responsive-1200">
                        {format(new Date(user.dat_pmt), "dd-MM-yyyy")}
                      </div>
                      <div className="w-300 responsive-998">{user.notes}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="button-modif button-modif-xxl">
            <div className="button-modif-action">
              <button
                className="btn-param"
                onClick={() => {
                  setAction("ajouter");
                  setActiveInput(""); //   Réinitialiser la sélection de l'utilisateur
                  const radioButtons = document.querySelectorAll(
                    'input[type="radio"][name="selectedUser"]'
                  );
                  radioButtons.forEach((button) => {
                    button.checked = false;
                  });
                }}
              >
                Ajouter
              </button>
              <button
                className={!activeInput ? "display-none" : "btn-param ml-5"}
                onClick={() => setAction("modifier")}
              >
                Modifier
              </button>
              <button
                className={
                  !activeInput ? "display-none" : "btn-param revoque ml-5"
                }
                onClick={oneExporter}
              >
                Exporter
              </button>
              <button className="btn-param revoque ml-5" onClick={tousExporter}>
                Tout exporter
              </button>
            </div>
            <div className="pagination responsive-1200">
              <div className="right-page">
                {Array.from(
                  {
                    length: Math.ceil(
                      filteredCotisation.length / cotisationPerPage
                    ),
                  },
                  (_, index) => {
                    const startPage = Math.max(currentPage - 2, 0);
                    const endPage = Math.min(
                      startPage + 3,
                      Math.ceil(filteredCotisation.length / cotisationPerPage)
                    );

                    if (index >= startPage && index < endPage) {
                      return (
                        <button
                          key={index}
                          onClick={() => paginate(index + 1)} // Appeler la fonction paginate avec le numéro de page correspondant
                          className={
                            currentPage === index + 1
                              ? "pageNum active"
                              : "pageNum"
                          }
                        >
                          {index + 1}
                        </button>
                      );
                    } else if (index === startPage - 1 && currentPage > 3) {
                      return <span key="ellipsis-start">{"<<"}</span>;
                    } else if (
                      index === endPage &&
                      currentPage <
                        Math.ceil(
                          filteredCotisation.length / cotisationPerPage
                        ) -
                          2
                    ) {
                      return <span key="ellipsis-end">{">>"}</span>;
                    }
                    return null;
                  }
                )}
              </div>
            </div>
          </div>
          <div className="bar mt-10"></div>
          <div className="mt-30">{renderForm()}</div>
          <div className="bar mt-30"></div>
          <div className="mt-30 text-center">@ProjetAdidy</div>
        </div>
      </div>
    </>
  );
}

export default CotisationAdminPage;
