import React from "react";

function Accueil() {
  return (
    <>
      <div className="bg-image">
        <div className="container-block">
          <div className="verset">
            <h3>Mathieu 10:8 :</h3>
            <p>
              “Guérissez les malades, ressuscitez les morts, purifiez les
              lépreux,
              <br />
              chassez les démons. Vous avez reçu gratuitement,
              <br />
              donnez gratuitement.”
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-30">
        <div className="flex-images">
          <div className="cadre-image cretien"></div>
          <div className="cadre-image cretiens"></div>
          <div className="cadre-image header"></div>
        </div>
      </div>
    </>
  );
}

export default Accueil;
