import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { format } from "date-fns";

function MembreUserPage() {
  const [selectedUser, setSelectedUser] = useState([]);
  const [dateselectedUser, setSelecteddateUser] = useState([]);
  const [usersect, setMembreSect] = useState([]);
  const [action, setAction] = useState(null);
  const [genreUser, setGenre] = useState("");
  const [indiceUser, setIndice] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Envoyer une requête GET pour récupérer l'utilisateurs connecté
    const show = async () => {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`, //authorization token pour récupérer l'utilisateurs
          },
        })
        .then((response) => {
          setSelectedUser(response.data.utilisateurs); //récupéré utilisateurs

          //Rénommez genre
          if (response.data.utilisateurs.genre !== "F") {
            setGenre("Masculin");
          } else {
            setGenre("Feminin");
          }

          //Rénommez indice
          if (
            response.data.utilisateurs.ind_resp === "1" ||
            response.data.utilisateurs.ind_resp === "O" ||
            response.data.utilisateurs.ind_resp === 1
          ) {
            setIndice("Responsable");
          } else {
            setIndice("Non responsable");
          }

          // Formatage de la date selon le format DD-MM-YYYY
          let dateFormatee = format(
            new Date(response.data.utilisateurs.dat_nais),
            "dd-MM-yyyy"
          );
          setSelecteddateUser(dateFormatee);
          // Au moment du login ou quand vous avez l'ID de l'utilisateur disponible
          localStorage.setItem("usersId", response.data.utilisateurs.id);
          // Exécuter show_membre_section après avoir défini usersId
          show_membre_section();
        })
        .catch((error) => {
          console.error(
            "Une erreur s'est produite lors du chargement des données:",
            error
          );
        });
    };

    show();
  }, []);

  //Admin role, récuperation utilisateurs section
  const show_membre_section = async () => {
    // Pour récupérer l'ID de l'utilisateur
    const usersId = localStorage.getItem("usersId");
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/show_membre_section/${usersId}`
      )
      .then((response) => {
        setLoading(false);
        if (response.data.membres_section.length === 0) {
          console.log("Secteur:", "Pas de section");
        } else {
          setMembreSect(response.data.membres_section);
          console.log(response.data.membres_section);
        }
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du chargement des données:",
          error
        );
      });
  };

  if (loading) {
    return (
      <>
        <div className="loading">
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      </>
    );
  }

  //pages Modifier membre
  function ModifierMembre() {
    const [error, setError] = useState(false);

    const nomRef = useRef();
    const prenomRef = useRef();
    const genreRef = useRef();
    const telRef = useRef();
    const e_mailRef = useRef();
    const adr_voieRef = useRef();
    const adr_cpRef = useRef();
    const adr_villeRef = useRef();
    const ind_respRef = useRef();
    const dat_naisRef = useRef();

    const handleSubmitModif = async (ev) => {
      ev.preventDefault();

      const membres = {
        nom: nomRef.current.value,
        prenom: prenomRef.current.value,
        genre: genreRef.current.value,
        tel: telRef.current.value,
        e_mail: e_mailRef.current.value,
        adr_voie: adr_voieRef.current.value,
        adr_cp: adr_cpRef.current.value,
        adr_ville: adr_villeRef.current.value,
        ind_resp: ind_respRef.current.value,
        dat_nais: dat_naisRef.current.value,
      };

      // requête pour ajouter un membre
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/modifier_membre/${selectedUser.id}`,
          membres
        )
        .then((response) => {
          console.log(response.data.utilisateurs);
          setError(false);
          setAction("modifierSection");
        })
        .catch((error) => {
          setError(true);
          console.log(error.response.data.errors);
        });
    };

    //enregistrer les modification user
    const enregistrer = async (ev) => {
      ev.preventDefault();

      const membres = {
        nom: nomRef.current.value,
        prenom: prenomRef.current.value,
        genre: genreRef.current.value,
        tel: telRef.current.value,
        e_mail: e_mailRef.current.value,
        adr_voie: adr_voieRef.current.value,
        adr_cp: adr_cpRef.current.value,
        adr_ville: adr_villeRef.current.value,
        ind_resp: ind_respRef.current.value,
        dat_nais: dat_naisRef.current.value,
      };

      // requête pour ajouter un membre
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/modifier_membre/${selectedUser.id}`,
          membres
        )
        .then((response) => {
          // console.log(response.data.utilisateurs);
          setError(false);
          window.location.reload();
        })
        .catch((error) => {
          setError(true);
          console.log(error.response.data.errors);
        });
    };

    return (
      <>
        <div className="container">
          <div className="container-form-membre">
            <h2 className="title">Éditer mes informations</h2>
            <form onSubmit={handleSubmitModif} id="monFormulaire">
              <div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Nom :</label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        id="name"
                        name="nom"
                        defaultValue={selectedUser.nom}
                        ref={nomRef}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Prénom :</label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        id="prenom"
                        name="prenom"
                        defaultValue={selectedUser.prenom}
                        ref={prenomRef}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Genre :</label>
                    </div>
                    <div className="block-form">
                      <select
                        className="input-form"
                        id="genre"
                        name="nom"
                        ref={genreRef}
                        required
                      >
                        <option value={selectedUser.genre}>
                          {selectedUser.genre === "M" ? "Masculin" : "Feminin"}
                        </option>
                        <option value="M">Masculin</option>
                        <option value="F">Feminin</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Téléphone :</label>
                    </div>
                    <div className="block-form">
                      <input
                        type="tel"
                        className="input-form"
                        id="tel"
                        name="tel"
                        defaultValue={selectedUser.tel}
                        ref={telRef}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="block-label-membre">
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Email :</label>
                      <span className="error">
                        {!error ? "" : <p>Email existe déjà !</p>}
                      </span>
                    </div>
                    <div className="block-form">
                      <input
                        type="email"
                        className="input-form"
                        id="email"
                        name="email"
                        defaultValue={selectedUser.e_mail}
                        ref={e_mailRef}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Adresse Voie :
                      </label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        id="voie"
                        name="voie"
                        defaultValue={selectedUser.adr_voie}
                        ref={adr_voieRef}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Code Postal :
                      </label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        id="postal"
                        name="postal"
                        defaultValue={selectedUser.adr_cp}
                        ref={adr_cpRef}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">Ville :</label>
                    </div>
                    <div className="block-form">
                      <input
                        type="text"
                        className="input-form"
                        id="ville"
                        name="ville"
                        defaultValue={selectedUser.adr_ville}
                        ref={adr_villeRef}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <label className="block-label-membre">
                        Date de naissance :
                      </label>
                    </div>
                    <div className="block-form">
                      <input
                        type="date"
                        className="input-form"
                        id="date"
                        name="date"
                        defaultValue={selectedUser.dat_nais}
                        ref={dat_naisRef}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="obligatoire">*</span>
                      <label className="block-label-membre">
                        Indice membre responsable :
                      </label>
                    </div>
                    <div className="block-form">
                      <select
                        className="input-form"
                        id="genre"
                        ref={ind_respRef}
                        required
                      >
                        <option value={selectedUser.ind_resp}>
                          {selectedUser.ind_resp === "1" ||
                          selectedUser.ind_resp === "O" ||
                          selectedUser.ind_resp === 1
                            ? "Responsable"
                            : "Non responsable"}
                        </option>
                        <option
                          value={
                            selectedUser.ind_resp === "1" ||
                            selectedUser.ind_resp === "O" ||
                            selectedUser.ind_resp === 1
                              ? 0
                              : 1
                          }
                        >
                          {selectedUser.ind_resp === "1" ||
                          selectedUser.ind_resp === "O" ||
                          selectedUser.ind_resp === 1
                            ? "Non responsable"
                            : "Responsable"}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={enregistrer} className="btn revoque">
                Enregistrer
              </button>
              <button type="submit" className="btn ml-5">
                Éditer Section
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  //Exporter mes données
  const exporter = (e) => {
    e.preventDefault();

    console.log("Exporter:", selectedUser.prenom);

    // Créer les données pour l'utilisateur sélectionné
    const data = [
      {
        nom: selectedUser.nom,
        prénom: selectedUser.prenom,
        genre: genreUser,
        tel: selectedUser.tel,
        e_mail: selectedUser.e_mail,
        adr_voie: selectedUser.adr_voie,
        adr_cp: selectedUser.adr_cp,
        adr_ville: selectedUser.adr_ville,
        ind_resp: indiceUser,
        dat_nais: selectedUser.dat_nais,
      },
    ];

    // Créer une feuille de calcul
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Membres");

    // Sauvegarder le fichier
    const fileName = `${selectedUser.prenom}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  //pages ajouter section membre
  function ModifierSectionMembre() {
    // const [error, setError] = useState(false);
    const [SA, setSA] = useState(!usersect.includes("SA") ? "" : "SA");
    const [SLK, setSLK] = useState(!usersect.includes("SLK") ? "" : "SLK");
    const [SVK, setSVK] = useState(!usersect.includes("SVK") ? "" : "SVK");
    const [AMF, setAMF] = useState(!usersect.includes("AMF") ? "" : "AMF");
    const [STK, setSTK] = useState(!usersect.includes("STK") ? "" : "STK");
    const [SFT, setSFT] = useState(!usersect.includes("SFT") ? "" : "SFT");
    const [SZM, setSZM] = useState(!usersect.includes("SZM") ? "" : "SZM");
    const [SF, setSF] = useState(!usersect.includes("SF") ? "" : "SF");
    const [SVM, setSVM] = useState(!usersect.includes("SVM") ? "" : "SVM");
    const [ST, setST] = useState(!usersect.includes("ST") ? "" : "ST");

    const handleSubmit = async (ev) => {
      ev.preventDefault();

      if (usersect.length !== 0) {
        // requête pour suprimer un utilisateur
        await axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/delete_membre_section/${selectedUser.id}`
          )
          .then((response) => {
            console.log(response.msg);
          })
          .catch((error) => {
            console.log("Erreur :", error);
          });
      }

      // Définir un tableau de références de sections
      const sectionTousModif = [SA, SLK, SVK, AMF, STK, SFT, SZM, SF, SVM, ST];

      console.log(sectionTousModif);

      // Parcourir chaque référence de section
      for (let i = 0; i < sectionTousModif.length; i++) {
        const sectionValueModif = sectionTousModif[i];

        // Vérifier si la valeur de la section est différente de ''
        if (sectionValueModif !== "") {
          const sections = {
            id_mbr: selectedUser.id,
            code_sect: sectionValueModif,
          };

          //Créer l'objet de section à envoyer à la base de données
          //requête pour ajouter un utilisateur
          await axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/add_membre_section`,
              sections
            )
            .then((response) => {
              console.log(response.data.Section_Membre);
            })
            .catch((error) => {
              console.log("Erreur :", error);
            });
        }
        if (i === sectionTousModif.length - 1) {
          window.location.reload(); // Actualise la page
        }
      }
    };

    const handleSubmission = (e, setter, value) => {
      e.preventDefault();
      setter(value);
    };

    const RadioButton = ({ onClick, checked, label }) => {
      return (
        <button
          onClick={onClick}
          className={checked ? "radio-binary cheked" : "radio-binary"}
        >
          {label}
        </button>
      );
    };

    const Ignorez = (e) => {
      e.preventDefault();
      window.location.reload(); // Actualise la page
    };

    return (
      <>
        <div className="container">
          <div className="container-form-section">
            <p className="title">
              <span className="capitalize">assigner section membre</span>
            </p>
            <div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Sekoly Alahady</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSA, "")}
                      checked={SA !== "SA"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSA, "SA")}
                      checked={SA === "SA"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Lehilahy Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSLK, "")}
                      checked={SLK !== "SLK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSLK, "SLK")}
                      checked={SLK === "SLK"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Vehivavy Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVK, "")}
                      checked={SVK !== "SVK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVK, "SVK")}
                      checked={SVK === "SVK"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Antoko Mpihira Fiderana</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setAMF, "")}
                      checked={AMF !== "AMF"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setAMF, "AMF")}
                      checked={AMF === "AMF"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Tanora Kristianina</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSTK, "")}
                      checked={STK !== "STK"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSTK, "STK")}
                      checked={STK === "STK"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Fampianarana T.A</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSFT, "")}
                      checked={SFT !== "SFT"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSFT, "SFT")}
                      checked={SFT === "SFT"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Zava Maneno</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSZM, "")}
                      checked={SZM !== "SZM"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSZM, "SZM")}
                      checked={SZM === "SZM"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Fifohazana</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSF, "")}
                      checked={SF !== "SF"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSF, "SF")}
                      checked={SF === "SF"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
              <div className="blockSection">
                <div className="assigne">
                  <p className="">Sampana Vokovoko Manga</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVM, "")}
                      checked={SVM !== "SVM"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setSVM, "SVM")}
                      checked={SVM === "SVM"}
                      label="Oui"
                    />
                  </div>
                </div>
                <div className="assigne">
                  <p className="">Sampana Tily</p>
                  <div className="section-status">
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setST, "")}
                      checked={ST !== "ST"}
                      label="Non"
                    />
                    <RadioButton
                      onClick={(e) => handleSubmission(e, setST, "ST")}
                      checked={ST === "ST"}
                      label="Oui"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button onClick={handleSubmit} className="btn revoque">
              Assigner
            </button>
            <button onClick={Ignorez} className="btn ml-5">
              Ignorez
            </button>
          </div>
        </div>
      </>
    );
  }

  //défault page
  function DefaultPages() {
    return (
      <>
        <div className="table-membre-users">
          <div>
            <div className="data-sm">
              <p className="subtitle">
                Identifiant :
                <span className="capitalize">{selectedUser.prenom}</span>
              </p>
              <div className="data-containte">
                <div className="dataBox">
                  <div className="champ">
                    <label>Matricule :</label>
                    <input
                      type="email"
                      className="input-form input-data"
                      id="userID"
                      readOnly
                      value={selectedUser.id}
                    />
                  </div>
                  <div className="champ">
                    <label>Nom : </label>
                    <input
                      type="text"
                      className="input-form input-data"
                      id="name"
                      readOnly
                      value={selectedUser.nom}
                    />
                  </div>
                  <div className="champ">
                    <label>Date de naissance :</label>
                    <input
                      type="text"
                      readOnly
                      className="input-form input-data w-small"
                      id="naissance"
                      value={
                        selectedUser.dat_nais === null
                          ? "Non définit"
                          : dateselectedUser
                      }
                    />
                  </div>
                  <div className="champ">
                    <label>Genre :</label>
                    <input
                      type="text"
                      className="input-form input-data"
                      id="genre"
                      readOnly
                      value={
                        selectedUser.genre === "M" ? "Masculin" : "Feminin"
                      }
                    />
                  </div>
                  <div className="champ">
                    <label>Prénom :</label>
                    <input
                      type="text"
                      className="input-form input-data"
                      id="lastname"
                      readOnly
                      value={selectedUser.prenom}
                    />
                  </div>
                  <div className="champ"></div>
                </div>
              </div>
            </div>
            <div className="data-sm">
              <p className="subtitle">Adresse</p>
              <div className="data-containte data-containte-user-data">
                <div className="dataBox">
                  <div className="champ">
                    <label>Email :</label>
                    <input
                      type="email"
                      className="input-form input-data"
                      id="email"
                      readOnly
                      value={selectedUser.e_mail}
                    />
                  </div>
                  <div className="champ">
                    <label>Voie adresse :</label>
                    <input
                      type="text"
                      className="input-form input-data w-small"
                      id="adress"
                      readOnly
                      value={selectedUser.adr_voie}
                    />
                  </div>
                  <div className="champ">
                    <label>Code postal :</label>
                    <input
                      type="text"
                      className="input-form input-data w-small"
                      id="code"
                      readOnly
                      value={selectedUser.adr_cp}
                    />
                  </div>
                  <div className="champ">
                    <label>Ville :</label>
                    <input
                      type="text"
                      className="input-form input-data"
                      id="ville"
                      readOnly
                      value={selectedUser.adr_ville}
                    />
                  </div>
                  <div className="champ">
                    <label>Indice Resp :</label>
                    <input
                      type="text"
                      className="input-form input-data w-small"
                      id="indice"
                      readOnly
                      value={
                        selectedUser.ind_resp === "1" ||
                        selectedUser.ind_resp === "O" ||
                        selectedUser.ind_resp === 1
                          ? "Responsable"
                          : "Non responsable"
                      }
                    />
                  </div>
                  <div className="champ"></div>
                </div>
              </div>
            </div>
            <div className="data-section-sm">
              <p className="subtitle">
                Membre section :
                <span className="capitalize">{selectedUser.prenom}</span>
              </p>
              <div className="data-containte data-containte-section data-containte-user-section">
              <div className="dataBox-sm responsive-600">
                    <div className="champ">
                      <p>Sampana vokovoko Manga : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S Lehilahy Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S vehivavy Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sekoly Alahady : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Antoko Mpihira Fiderana : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S Tanora Kristianina : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>S Fampianarana T.A : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sampana zava-maneno : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sampana Fifohazana : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>Sampana Tily: </p>
                      <div>
                        <button
                          className={
                            usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ"></div>
                    <div className="champ"></div>
                  </div>
                  <div className="dataBox-sm-600 responsive-on-600">
                    <div className="champ">
                      <p>SVM : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SLK : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SLK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SVK : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SVK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SA : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SA")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>AMF : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("AMF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>STK: </p>
                      <div>
                        <button
                          className={
                            usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("STK")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SFT : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SFT")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SZM : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SZM")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>SF : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("SF")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                    <div className="champ">
                      <p>ST : </p>
                      <div>
                        <button
                          className={
                            usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Oui
                        </button>
                        <button
                          className={
                            !usersect.includes("ST")
                              ? "radio-binary cheked"
                              : "radio-binary"
                          }
                        >
                          Non
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  //gestion pages avec button
  const renderForm = () => {
    switch (action) {
      case "modifier":
        return <ModifierMembre />;
      case "modifierSection":
        return <ModifierSectionMembre />;
      default:
        return <DefaultPages />;
    }
  };

  return (
    <>
      <div className="container-fluide">
        <div className="container-lg">
          <div className="mt-30">{renderForm()}</div>
          <div
            className={
              action === "modifier" || action === "modifierSection"
                ? "display-none"
                : "button-modif button-modif-xxl"
            }
          >
            <button className="btn-param" onClick={() => setAction("modifier")}>
              Modifier
            </button>
            <button className="btn-param revoque" onClick={exporter}>
              Exporter
            </button>
          </div>
          <div
            className={
              action === "modifier" || action === "modifierSection"
                ? "display-none"
                : "bar"
            }
          ></div>
          <div className="mt-30 text-center">@ProjetAdidy</div>
        </div>
      </div>
    </>
  );
}

export default MembreUserPage;
